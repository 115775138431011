import smoothScrollTo from 'smooth-scroll-to';

import { addEventListeners } from '../utils/common';

document.addEventListener('DOMContentLoaded', () => {
  const scrollToTarget = (target) => {
    if (target) {
      const position = target.offsetTop;

      smoothScrollTo(position, 600);
    }
  };

  addEventListeners('.js-scroll-to', 'click', (e, elm) => {
    e.preventDefault();

    const id = elm
      .getAttribute(elm.href ? 'href' : 'data-target')
      .replace('#', '');
    const target = document.querySelector(`#${id}, [data-id="${id}"]`);

    scrollToTarget(target);
  });
});
