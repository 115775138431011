import emailJS from 'emailjs-com';
import { getClientData, resetForm } from '/src/scripts/utils/common';

import { makePopup } from '/src/scripts/es-modules/popup';
import Validator from '/src/scripts/es-modules/validator';

const form = document.querySelector('.js-feedback-form');
const submitButton = document.querySelector('.js-feedback-submit');

const modalMessage = makePopup({
  content: `
    <h2>Thanks for your message!</h2>
    <p>We will get back to you as soon as possible - keep an eye on your inbox.</p>
  `,
  footerButton: 'Done',
});

function pendingStop() {
  submitButton.classList.remove('btn_loading');
}

function pendingStart() {
  submitButton.classList.add('btn_loading');
}

document.addEventListener('DOMContentLoaded', async () => {
  new Validator(form, async function (form, values) {
    pendingStart();

    const clientData = await getClientData();

    const formData = {
      message: values.message,
      from_name: values.name,
      from_mail: values.email,
      ...clientData,
    };

    emailJS
      .send('service_ou4lyil', 'template_kwpvdrr', formData)
      .then(() => {
        window.dataLayer.push({
          ...formData,
          event: 'onSubmit',
          action: 'Form Submitted',
          category: 'Contact form',
        });

        modalMessage.open();

        resetForm(form);
        pendingStop();
      })
      .catch(() => {
        pendingStop();
      });
  });
});
