import ScrollOut from 'scroll-out';

import { loop } from '../utils/common';

let scrollOut;

export function initScrollOut() {
  scrollOut = ScrollOut({
    once: true,
    targets: '.wow:not(.posts__item_more)',
    threshold: 0,
    cssProps: {
      index: true,
    },
    onShown: (element) => {
      const delay = Number.parseFloat(
        getComputedStyle(element).transitionDelay,
      );
      const duration = Number.parseFloat(
        getComputedStyle(element).transitionDuration,
      );

      setTimeout(() => {
        element.classList.remove('wow', 'wow_fade-up', 'wow_scale-in');
      }, (delay + duration) * 1010);
    },
  });
}

export function updateScrollOut() {
  if (scrollOut) {
    scrollOut.teardown();
  }

  initScrollOut();
}

document.addEventListener('DOMContentLoaded', () => {
  let timer = null;

  const scrollHandler = () => {
    clearTimeout(timer);

    timer = setTimeout(function () {
      loop('.wow', (element) => {
        const { y, height } = element.getBoundingClientRect();

        if (y + height > 0) return;

        element.classList.remove('wow', 'wow_fade-up', 'wow_scale-in');
      });

      initScrollOut();

      window.removeEventListener('scroll', scrollHandler, false);
    }, 150);
  };

  scrollHandler();

  window.addEventListener('scroll', scrollHandler, false);
});
