import { addEventListeners } from '../utils/common';

window.dataLayer = window.dataLayer || [];

addEventListeners('.js-link-demo-ui', 'click', () => {
  window.dataLayer.push({
    event: 'onClick',
    action: 'Click on link',
    category: 'Click on demo ui',
  });
});

addEventListeners('.js-link-klipme', 'click', () => {
  window.dataLayer.push({
    event: 'onClick',
    action: 'Click on link',
    category: 'Click on klipme',
  });
});
