import emailJS from 'emailjs-com';

import './es-modules/analytics';
import './es-modules/scroll-to';
import './es-modules/scroll-reveal';

import '/src/components/next/globals/lift/lift';

import '/src/components/next/blocks/contactus/contactus';

// window.history.scrollRestoration = 'manual';

emailJS.init('user_eYZMbSfh5QY63OkYXzPni');

document.addEventListener('DOMContentLoaded', () => {
  const { referrer } = document;

  const currentUrl = location.href;
  const initialUrl = localStorage.getItem('initial_url');
  const referrerUrl = localStorage.getItem('referrer_url');

  if (!initialUrl) {
    localStorage.setItem('initial_url', currentUrl);
  }

  if (!referrerUrl) {
    localStorage.setItem(
      'referrer_url',
      referrer.includes(location.host) ? currentUrl : referrer,
    );
  }
});
