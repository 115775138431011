import { modal as Modal } from 'tingle.js';

export function makePopup(props) {
  const { content, onClose, cssClass = [], footerButton } = props;

  const modal = new Modal({
    footer: true,
    onClose,
    cssClass,
    closeMethods: [],
  });

  modal.setContent(content);

  modal.addFooterBtn(
    '',
    'tingle-modal-close | btn btn_icon btn_icon-md btn_primary | icon icon_close | js-contactus-close',
    () => modal.close(),
  );

  if (footerButton) {
    modal.addFooterBtn(
      footerButton,
      'tingle-modal-finish | btn btn_base btn_base-lg btn_round btn_default',
      () => modal.close(),
    );
  }

  return modal;
}
