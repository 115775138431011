export function loop(selector, callback) {
  let index;
  let elements =
    typeof selector === 'string'
      ? document.querySelectorAll(selector)
      : selector;

  for (index = 0; index < elements.length; index++) {
    callback(elements[index], index);
  }
}

export function hasClass(element, className) {
  return element.classList.contains(className);
}

export function hasTouch() {
  return 'ontouchstart' in window;
}

export function getIndex(element) {
  let parent = element.parentNode;
  return Array.prototype.indexOf.call(parent.children, element);
}

export function resetForm(form) {
  let fields = form.querySelectorAll('input, textarea');

  removeClassName(fields, 'invalid');

  form.reset();
}

export function removeClassName(selector, classNames) {
  const elements =
    typeof selector !== 'object'
      ? document.querySelectorAll(selector)
      : selector;

  loop(elements, function (element) {
    element.classList.remove(...classNames);
  });
}

export function addEventListeners(selector, eventName, callback, options) {
  const elements =
    typeof selector !== 'object'
      ? document.querySelectorAll(selector)
      : selector;

  loop(elements, (element, index) => {
    element.addEventListener(
      eventName,
      (e) => {
        callback(e, element, index);
      },
      options,
    );
  });
}

export function addEventsListener(selector, eventList, callback) {
  const element =
    typeof selector !== 'object' ? document.querySelector(selector) : selector;

  if (!element) return true;

  loop(eventList, (eventName) => {
    element.addEventListener(eventName, (e) => {
      callback(e, element);
    });
  });
}

export async function getIP() {
  try {
    const response = await fetch('https://api.ipify.org');
    return await response.text();
  } catch (e) {
    return undefined;
  }
}

export function getCookie(name) {
  const value = document.cookie
    .split('; ')
    .find((row) => row.startsWith(name))
    ?.split('=')[1];

  return value || 'unknown';
}

export async function getClientData() {
  let clientIp = 'unknown';

  const clientId = getCookie('_ga')?.slice(6);
  const currentUrl = location.href;
  const initialUrl = localStorage.getItem('initial_url');
  const referrerUrl = localStorage.getItem('referrer_url');

  try {
    clientIp = await getIP();
  } catch (e) {
    console.error(e);
  }

  return {
    client_ip: clientIp,
    client_id: clientId,
    current_url: currentUrl,
    initial_url: initialUrl,
    referrer_url: referrerUrl,
  };
}
