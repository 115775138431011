import { nanoid } from 'nanoid';
import { slideDown, slideUp } from '/src/scripts/utils/slide-effect';
import { addEventListeners, loop } from '/src/scripts/utils/common';

function closeCases(uid) {
  loop('.collapse-item_open', (el) => {
    if (el.uid === uid) return;

    el.classList.remove('collapse-item_open');
    slideUp(el.querySelector('.collapse-item__body'), 200);
  });
}

addEventListeners('.collapse-item__head', 'click', (e, elm) => {
  const box = elm.parentElement;
  const body = elm.nextElementSibling;

  if (box.classList.contains('collapse-item_open')) {
    box.classList.remove('collapse-item_open');

    slideUp(body, 200);
  } else {
    box.uid = nanoid();
    box.classList.add('collapse-item_open');

    closeCases(box.uid);
    slideDown(body, 200);
  }
});
