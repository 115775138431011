import emailJS from 'emailjs-com';

import {
  getClientData,
  resetForm,
  addEventListeners,
} from '/src/scripts/utils/common';

import { makePopup } from '/src/scripts/es-modules/popup';
import Validator from '/src/scripts/es-modules/validator';

const form = document.querySelector('.js-contactus-form');
const fragment = document.createDocumentFragment();

const modalForm = makePopup({
  content: `
    <div id="contactus-case"></div>
  `,
});

const modalMessage = makePopup({
  content: `
    <h2>Thanks for your message!</h2>
    <p>We will get back to you as soon as possible - keep an eye on your inbox.</p>
  `,
  footerButton: 'Back to website',
});

const closeButton = document.querySelector('.js-contactus-close');
const submitButton = document.querySelector('.js-contactus-submit');

function pendingStop() {
  closeButton.removeAttribute('disabled');
  submitButton.classList.remove('btn_loading');
}

function pendingStart() {
  closeButton.setAttribute('disabled', '');
  submitButton.classList.add('btn_loading');
}

document
  .getElementById('contactus-case')
  .appendChild(fragment.appendChild(form));

document.addEventListener('DOMContentLoaded', () => {
  new Validator(form, async function (form, values) {
    pendingStart();

    const clientData = await getClientData();

    const formData = {
      message: values.message,
      from_name: values.name,
      from_mail: values.email,
      ...clientData,
    };

    emailJS
      .send('service_ou4lyil', 'template_kwpvdrr', formData)
      .then(() => {
        window.dataLayer.push({
          ...formData,
          event: 'onSubmit',
          action: 'Form Submitted',
          category: 'Contact form',
        });

        modalMessage.open();
        modalForm.close();

        resetForm(form);
        pendingStop();
      })
      .catch(() => {
        pendingStop();
      });
  });
});

addEventListeners('.js-contactus-open', 'click', (e) => {
  modalForm.open();
  e.preventDefault();
});
