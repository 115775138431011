import Swiper, { Navigation } from 'swiper';

const container = document.querySelector('.logos__show');
const arrowPrevEl = document.querySelector('.logos__arrow_prev');
const arrowNextEl = document.querySelector('.logos__arrow_next');

const swiper = new Swiper(container, {
  loop: true,
  grabCursor: true,
  spaceBetween: 24,
  slidesPerView: 1,
  modules: [Navigation],
  navigation: {
    prevEl: arrowPrevEl,
    nextEl: arrowNextEl,
  },
  breakpoints: {
    1024: {
      spaceBetween: 48,
      slidesPerView: 4,
    },
    768: {
      spaceBetween: 24,
      slidesPerView: 3,
    },
    480: {
      spaceBetween: 24,
      slidesPerView: 2,
    },
  },
});
