import smoothScrollTo from 'smooth-scroll-to';

document.addEventListener('DOMContentLoaded', () => {
  const btn = document.querySelector('.lift__btn');

  if (btn) {
    window.addEventListener('scroll', () => {
      const scrollTop = window.scrollY;

      if (scrollTop >= window.innerHeight) {
        btn.classList.add('lift__btn_show');
      } else {
        btn.classList.remove('lift__btn_show');
      }
    });

    btn.addEventListener('click', () => {
      smoothScrollTo(0, 1200);
    });
  }
});
