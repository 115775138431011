import { addEventListeners, getIndex } from '../utils/common';
import { updateScrollOut } from './scroll-reveal';

addEventListeners('.tabs__link', 'click', (e, element) => {
  const index = getIndex(element) + 2;

  const box = element.closest('.tabs');
  const nextItem = box.querySelector(`.tabs__item:nth-child(${index})`);

  const currentLink = box.querySelector('.tabs__link_active');
  const currentItem = box.querySelector('.tabs__item_active');

  element.classList.add('tabs__link_active');
  currentLink.classList.remove('tabs__link_active');

  nextItem.classList.add('tabs__item_active');
  currentItem.classList.remove('tabs__item_active');

  updateScrollOut();
});
